<template>
	<div class="fixMsgRoll" style="" v-if="detData.length>0">
		<div class="msgRollCon" style="">
			<div class="msgRollLeft" style="">
				<i class="el-icon-bell"></i>
			</div>
			<div class="msgRollMain" style="">
				<el-carousel height="30px" direction="vertical" :autoplay="true" :interval="3000" :arrow="'never'">
					<el-carousel-item v-for="(item,index) in detData" :key="index">
						<div class="msgRollList">
							<el-link type="primary" @click="toDet($event,item)">
								{{ item.msgText }}
							</el-link>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<!-- <el-button @click="initData()">{{$t('i18nn_a9e1d1c2c5eb6525')}}</el-button> -->
			<el-link type="primary" @click="toMoreList()">
				<span>{{$t('i18nn_c3525eb110db58aa')}}</span>...
			</el-link>
		</div>
	</div>
</template>
<script>
	export default {
		components: {},
		data() {
			return {
				loading_load: false,
				detData: [],
			};
		},
		activated() {
			
		},
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				this.detData = [];
				//初始化上传控件
				this.getData();
			},
			toDet(event, row) {
				event.stopPropagation();
				this.$router.push({name:"NoticeDet",query:{id:row.id}})
			},
			toMoreList() {
				// event.stopPropagation();
				this.$router.push({name:"NoticeList"})
			},
			//请求分页数据
			getData() {
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhNoticeList, {
						"offset": "0",
						"limit": "10",
						"msgType":"10",
						"status":"10"
					})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_4195755cd53e871a'));
						console.log(data);
						this.loading_load = false;
						if (200 == data.code) {
							this.detData = data.rows;
						} else {
							// this.$alert('查询失败,请重试', this.$t('tips.tipsTitle'), {
							// 	type: 'warning'
							// });
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_load = false;
						// this.$alert('查询失败,请重试！', this.$t('tips.tipsTitle'), {
						// 	type: 'warning'
						// });
					});
			},
		}
	};
</script>

<style scoped lang="less">
	.fixMsgRoll {
		position: sticky;
		top:0;
		background-color: #fff;
	}
	.msgRollCon {
		display: flex;
		align-items: center;
		font-size: 16px;
		line-height: 20px;
		// font-weight:bold;
		padding:10px;
		box-shadow:0 3px 10px 0 rgb(0 0 0 / 10%);
	}

	.msgRollLeft {
		color: #E6A23C;
		font-size: 20px;
		padding: 0 10px;
	}
	.msgRollMain {
		flex: 1;
	}

	.msgRollList {
		padding: 5px;
		font-size: 16px;
	}
</style>
