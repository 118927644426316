<template>
	<div>
		<!-- 消息 -->
		<messageRoll></messageRoll>
		<!-- <div class="top-wall" style="">
			<div>
				<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="top-wall-right">
			</div>
		</div> -->
		<div class="home-con">
			<div class="left-block">
				<div class="l-block-top">
					<el-card class="home-card" v-loading="loading_1">
						<div @click="toPageName($event,'WarehouseDropShipping')">
							<div class="home-card-title">
								<span>{{$t('i18nn_1595bdab4d7bf7c2')}}</span>
								<br />
								<div class="home-card-subtitle"><span>{{$t('i18nn_684a2afb069b6016')}}</span><i
										class="el-icon-right"></i></div>
							</div>
							<div class="home-card-item-top">
								<div class="home-card-item home-card-item-s1">
									<div class="home-card-name">{{$t('i18nn_396cb19960e15651')}}</div>
									<div class="home-card-val">{{ OutDataByDS.totalCount }}</div>
								</div>
								<div class="home-card-item home-card-item-s2">
									<div class="home-card-name">{{$t('i18nn_16d3ec8d8ecbac75')}}</div>
									<div class="home-card-val">{{ OutDataByDS.overTimeCount }}</div>
								</div>
							</div>
							<div class="home-card-item home-card-item-s3 card-active-hover"
								@click="toPageName($event,'WarehouseDropShipping',{state:'10'})">
								<div class="home-card-name">{{$t('i18nn_7d814d9a1e592965')}}</div>
								<div class="home-card-val">{{ OutDataByDS.noHandledCount }}</div>
							</div>
							<div class="home-card-item home-card-item-s4 card-active-hover"
								@click="toPageName($event,'WarehouseDropShipping',{state:OutDataByDS.whStatus})">
								<div class="home-card-name">{{$t('i18nn_0ae3aed4e3b5423d')}}</div>
								<div class="home-card-val">{{ OutDataByDS.handleingCount }}</div>
							</div>
							<div class="home-card-item home-card-item-s5 card-active-hover"
								@click="toPageName($event,'WarehouseDropShipping',{state:'35'})">
								<div class="home-card-name">{{$t('i18nn_a0acfaee54f06458')}}</div>
								<div class="home-card-val">{{ OutDataByDS.handledCount }}</div>
							</div>
							<div class="home-card-item home-card-item-s3 card-active-hover"
								@click="toPageName($event,'WarehouseDropShipping',{state:'60'})">
								<div class="home-card-name">{{$t('i18nn_8eb6e92d013f9555')}}</div>
								<div class="home-card-val">{{ OutDataByDS.stopedCount }}</div>
							</div>
						</div>

					</el-card>
					<el-card class="home-card" v-loading="loading_2">
						<div @click="toPageName($event,'WarehouseShipments')">
							<div class="home-card-title">
								<span>{{$t('i18nn_1595bdab4d7bf7c2')}}</span>
								<br />
								<div class="home-card-subtitle"><span>{{$t('i18nn_6b1945319eb18d5f')}}</span><i
										class="el-icon-right"></i></div>
							</div>
							<div class="home-card-item-top">
								<div class="home-card-item home-card-item-s1">
									<div class="home-card-name">{{$t('i18nn_396cb19960e15651')}}</div>
									<div class="home-card-val">{{ OutDataByTO.totalCount }}</div>
								</div>
								<div class="home-card-item home-card-item-s2">
									<div class="home-card-name">{{$t('i18nn_16d3ec8d8ecbac75')}}</div>
									<div class="home-card-val">{{ OutDataByTO.overTimeCount }}</div>
								</div>
							</div>


							<div class="home-card-item home-card-item-s3 card-active-hover"
								@click="toPageName($event,'WarehouseShipments',{state:'10'})">
								<div class="home-card-name">{{$t('i18nn_7d814d9a1e592965')}}</div>
								<div class="home-card-val">{{ OutDataByTO.noHandledCount }}</div>
							</div>
							<div class="home-card-item home-card-item-s4 card-active-hover"
								@click="toPageName($event,'WarehouseShipments',{state:OutDataByTO.whStatus})">
								<div class="home-card-name">{{$t('i18nn_0ae3aed4e3b5423d')}}</div>
								<div class="home-card-val">{{ OutDataByTO.handleingCount }}</div>
							</div>
							<div class="home-card-item home-card-item-s5 card-active-hover"
								@click="toPageName($event,'WarehouseShipments',{state:'30'})">
								<div class="home-card-name">{{$t('i18nn_a0acfaee54f06458')}}</div>
								<div class="home-card-val">{{ OutDataByTO.handledCount }}</div>
							</div>
						</div>
					</el-card>

					<!-- <el-card class="home-card" v-loading="loading_6">
						<div @click="toPageName($event,'TransportOutWh')">
							<div class="home-card-title">
								<span>{{$t('i18nn_65fe75ea422bdc50')}}</span>
							</div>
							<div class="home-card-item2 home-card-item-s3 card-active-hover"
								@click="toPageName($event,'TransportOutWh',{state:'10'})">
								<div class="home-card-name">{{$t('i18nn_7d814d9a1e592965')}}</div>
								<div class="home-card-val">{{ STRData.waithandle }}</div>
							</div>
							<div class="home-card-item2 home-card-item-s4 card-active-hover"
								@click="toPageName($event,'TransportOutWh',{state:'60'})">
								<div class="home-card-name">{{$t('i18nn_0ae3aed4e3b5423d')}}</div>
								<div class="home-card-val">{{ STRData.waithanding }}</div>
							</div>

							<div class="home-card-item2 home-card-item-s5 card-active-hover"
								@click="toPageName($event,'TransportOutWh',{state:'70'})">
								<div class="home-card-name">{{$t('i18nn_dcc830098ae4c9fd')}}</div>
								<div class="home-card-val">{{ STRData.waithandout }}</div>
							</div>
						</div>
					</el-card> -->

					<el-card class="home-card" v-loading="loading_5">
						<div @click="toPageName($event,'workOrderList')">
							<div class="home-card-title">
								<span>{{$t('i18nn_fd445581cff561bf')}}</span>
							</div>
							<!-- <div class="home-card-item-top">
								<div class="home-card-item home-card-item-s1">
									<div class="home-card-name">{{$t('i18nn_396cb19960e15651')}}</div>
									<div class="home-card-val">{{ OutDataByTO.totalCount }}</div>
								</div>
								<div class="home-card-item home-card-item-s2">
									<div class="home-card-name">{{$t('i18nn_16d3ec8d8ecbac75')}}</div>
									<div class="home-card-val">{{ OutDataByTO.overTimeCount }}</div>
								</div>
							</div> -->
							<!-- <div style="display: flex;"> -->
							<div class="home-card-item2 home-card-item-s3 card-active-hover"
								@click="toPageName($event,'workOrderList',{state:'10'})">
								<div class="home-card-name">{{$t('i18nn_dd697b8fa767da8b')}}</div>
								<div class="home-card-val">{{ valueAddData.noHandledCount }}</div>
							</div>
							<div class="home-card-item2 home-card-item-s4 card-active-hover"
								@click="toPageName($event,'workOrderList',{state:'20'})">
								<div class="home-card-name">{{$t('i18nn_42d98013e99e7380')}}</div>
								<div class="home-card-val">{{ valueAddData.handleingCount }}</div>
							</div>
							<!-- </div> -->


							<!-- <div class="home-card-item home-card-item-s5 card-active-hover"
								@click="toPageName($event,'WarehouseShipments',{state:'30'})">
								<div class="home-card-name">{{$t('i18nn_a0acfaee54f06458')}}</div>
								<div class="home-card-val">{{ OutDataByTO.handledCount }}</div>
							</div> -->
						</div>
					</el-card>



					<!-- <el-card class="home-card" v-loading="loading_6">
						<div @click="toPageName($event,'TransportInWh')">
							<div class="home-card-title">
								<span>{{$t('i18nn_1f1bd51a9a045025')}}</span>
							</div>
								<div class="home-card-item home-card-item-s3 card-active-hover"
									@click="toPageName($event,'TransportInWh',{state:'10'})">
									<div class="home-card-name">{{$t('i18nn_7d814d9a1e592965')}}</div>
									<div class="home-card-val">{{ TSCCData.waitRecive }}</div>
								</div>
						</div>
					</el-card> -->

				</div>
				<!-- <el-card class="announcement-block">
					<div class="announcement-title">
						<div>{{$t('i18nn_7d6f758a93cb33b5')}}</div>
						<div>
							<el-badge :is-dot="notifyMessageList.length>0" class="">
								<el-button icon="el-icon-message-solid" circle @click="showNotification()"></el-button>
							</el-badge>
						</div>
					</div>
					<div class="announcement">
						<h2>【{{$t('home.long_1')}}】</h2>
						<ul>
							<li>{{$t('home.long_2')}}</li>
							<li>{{$t('home.long_3')}}</li>
							<li>{{$t('home.long_4')}}</li>
						</ul>
					</div>
				</el-card> -->

				<!-- 公告 -->
				<noticeBoard></noticeBoard>

				<!-- <div class="banner-list">
					<el-carousel :interval="5000">
						<el-carousel-item>
							<el-image style="width: 100%; height: 100%"
								:src="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/1.jpg'"
								:preview-src-list="['https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/1.jpg']">
							</el-image>
						</el-carousel-item>
						<el-carousel-item>
							<el-image style="width: 100%; height: 100%"
								:src="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/2.jpg'"
								:preview-src-list="['https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/2.jpg']">
							</el-image>
						</el-carousel-item>
						<el-carousel-item>
							<el-image style="width: 100%; height: 100%"
								:src="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/3.jpg'"
								:preview-src-list="['https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/3.jpg']">
							</el-image>
						</el-carousel-item>
					</el-carousel>
				</div> -->

			</div>

			<div class="right-card">
				<div class="r-block-top">
					<el-card class="home-card2" v-loading="loading_4">
						<div class="home-card-title">{{$t('i18nn_591c1b2fa3e699cc')}}</div>
						<div class="home-card-item2 home-card-item-s3 card-active-hover"
							@click="toPageName($event,'InWarehouse',{state:'10'})">
							<div class="home-card-name">{{$t('i18nn_af1a2cc986597e90')}}</div>
							<div class="home-card-val">{{InWhStatisticsData.inWhApplyCount}}</div>
						</div>
						<div class="home-card-item2 home-card-item-s4 card-active-hover"
							@click="toPageName($event,'InWarehouse',{state:'20'})">
							<div class="home-card-name">{{$t('i18nn_15aeae6cfcd5725a')}}</div>
							<div class="home-card-val">{{InWhStatisticsData.receiptCount}}</div>
						</div>
						<div class="home-card-item2 home-card-item-s5 card-active-hover" @click="toPageName($event,'InWarehouse')">
							<div class="home-card-name">{{$t('i18nn_3fe2f60f8a8b9214')}}</div>
							<div class="home-card-val">{{InWhStatisticsData.inWhingCount}}</div>
						</div>
						<!-- <div class="home-card-item2 home-card-item-s3 card-active-hover" @click="toPageName($event, 'TransportInWh', { state: '10' })">
							<div class="home-card-name">{{$t('i18nn_1f1bd51a9a045025')}}</div>
							<div class="home-card-val">{{ TSCCData.waitRecive }}</div>
						</div> -->
					</el-card>
					<el-card class="home-card2" v-loading="loading_6">
						<div @click="toPageName($event,'TransportInWh')">
							<div class="home-card-title">
								<span>{{$t('i18nn_1f1bd51a9a045025')}}</span>
							</div>
							<div class="home-card-item2 home-card-item-s3 card-active-hover"
								@click="toPageName($event,'TransportInWh',{state:'10'})">
								<div class="home-card-name">{{$t('i18nn_7d814d9a1e592965')}}</div>
								<div class="home-card-val">{{ TSCCData.waitRecive }}</div>
							</div>
						</div>
					</el-card>

					<el-card class="home-card2" v-loading="loading_8">
						<div class="home-card-title">
							<span>{{$t('i18nn_7689a837889ed035')}}</span>
						</div>
						<div class="home-card-item2 home-card-item-s3 card-active-hover"
							@click="toPageName($event, 'ExpressTrunckNotOnlineList', { state: '' })">
							<div class="home-card-name">{{$t('i18nn_28c0ca2da0ad71dc')}}</div>
							<div class="home-card-val">{{ ExpressTrunckData.total }}</div>
						</div>

					</el-card>

					<el-card class="home-card2" v-loading="loading_3">
						<div class="home-card-title">{{$t('i18nn_a967bc023b5157c4')}}</div>
						<div class="home-card-item2 home-card-item-s5 card-active-hover"
							@click="toPageName($event,'OrderStatisticsByMon')">
							<div class="home-card-name">{{$t('i18nn_684a2afb069b6016')}}</div>
							<div class="home-card-val" v-if="OrderStatisticsData.SDS">
								{{ OrderStatisticsData.SDS.totalCount }}
							</div>
						</div>
						<div class="home-card-item2 home-card-item-s4 card-active-hover"
							@click="toPageName($event,'OrderStatisticsByMon')">
							<div class="home-card-name">{{$t('i18nn_57495c18e2baebdc')}}</div>
							<div class="home-card-val" v-if="OrderStatisticsData.STF">
								{{ OrderStatisticsData.STF.totalCount }}
							</div>
						</div>
						<!-- <div class="home-card-item2 home-card-item-s3">
							<div class="home-card-name">{{$t('i18nn_199f5bded0b23976')}}</div>
							<div class="home-card-val">{{ OrderStatisticsData.totalCount }}</div>
						</div> -->
					</el-card>

					<el-card class="home-card2" style="" v-loading="loading_7">
						<div class="home-card-title">
							<span>{{$t('i18nn_87f891127ffff1f8')}}</span>
							<!-- <br />({{CargoVolumeData_dateStr}}) -->
						</div>
						<!-- <div class="home-card-item-top"> -->
						<div class="home-card-item2 home-card-item-s5 card-active-hover"
							@click="toPageName($event,'CargoVolumeStatisticsByDay')">
							<div class="home-card-name"><span>{{$t('i18nn_5ada761d1917f8bc')}}</span>(m³)</div>
							<div class="home-card-val">{{ CargoVolumeData.totalVol }}</div>
						</div>
						<!-- </div> -->
						<!-- <div class="home-card-item-top"> -->
						<div class="home-card-item2 home-card-item-s4 card-active-hover"
							@click="toPageName($event,'CargoVolumeStatisticsByDay')">
							<div class="home-card-name"><span>{{$t('i18nn_ea71165e067be24e')}}</span>(kg)</div>
							<div class="home-card-val">{{ CargoVolumeData.totalWeight }}</div>
						</div>
						<!-- </div> -->
					</el-card>
					<!-- <el-card class="home-card2" style="width: 528px" v-loading="loading_5">
						<div class="home-card-title">{{$t('i18nn_fd445581cff561bf')}}</div>
						<div class="home-card-item2 home-card-item-s5 card-active-hover"
							@click="toPageName($event,'OrderStatisticsByMon')">
							<div class="home-card-name">{{$t('i18nn_7d814d9a1e592965')}}</div>
							<div class="home-card-val" v-if="valueAddData">
								{{ valueAddData.noHandledCount }}
							</div>
						</div>
						<div class="home-card-item2 home-card-item-s4 card-active-hover"
							@click="toPageName($event,'OrderStatisticsByMon')">
							<div class="home-card-name">{{$t('i18nn_0ae3aed4e3b5423d')}}</div>
							<div class="home-card-val" v-if="valueAddData">
								{{ valueAddData.handleingCount }}
							</div>
						</div>
					</el-card> -->

				</div>



				


				<HomeOther></HomeOther>
			</div>
		</div>
		<!-- 通知icon悬浮 -->
		<!-- <div style="position: fixed; bottom: 100px; right: 50px;">
			<el-badge :is-dot="notifyMessageList.length>0" class="">
				<el-button icon="el-icon-message-solid" circle @click="showNotification()"></el-button>
			</el-badge>
		</div> -->
	</div>
</template>
<script>
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import HomeOther from '@/components/StorageCenter/Home/HomeOther.vue';
	import notifyMessage from '@/components/StorageCenter/Home/notifyMessage.js';
	import messageRoll from './messageRoll.vue';
	import noticeBoard from './noticeBoard.vue';
	export default {
		components: {
			SelAgentUser,
			HomeOther,
			messageRoll,
			noticeBoard
		},
		data() {
			return {
				loading_1: false,
				OutDataByDS: {},
				loading_2: false,
				OutDataByTO: {},
				loading_3: false,
				OrderStatisticsData: {},
				loading_4: false,
				InWhStatisticsData: {},
				loading_5: false,
				valueAddData: {},
				loading_6: false,
				STRData: {},
				TSCCData: {},

				loading_7: false,
				CargoVolumeData: {},
				// CargoVolumeData_dateStr:"",

				loading_8: false,
				ExpressTrunckData: {},

				filterData: {
					agentUser: ""
				},
				notifyMessageList: notifyMessage,
				notifySetTimeOut: null,
			};
		},
		activated() {
			// console.log('activated');
			// this.initData();
		},
		created() {
			// console.log('homeNotif', window.sessionStorage.getItem("homeNotif"));
			// if ('1' != window.sessionStorage.getItem("homeNotif")) {
			// 	this.showNotification();
			// }
		},
		mounted() {
			console.log('mounted');
			this.initData();
		},
		methods: {
			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			initData() {
				this.getOutDataByDropShipping();
				this.getOutDataByTransferringOrder();
				this.getOrderStatisticsData();
				this.getInWhStatisticsData();
				this.getValueAddStatisticsData();
				this.getSTRStatisticsData();
				this.getCargoVolumeStatisticsData();
				//未上线快递运单
				this.getExpressTrunckTotal();
			},
			// showNotification() {
			// 	clearInterval(this.notifySetTimeOut);
			// 	for (let i = 0, ln = this.notifyMessageList.length; i < ln; i++) {
			// 		this.notifySetTimeOut = setTimeout(() => {
			// 			this.$notify({
			// 				title: this.notifyMessageList[i].title,
			// 				type: 'info',
			// 				position: 'bottom-right',
			// 				duration: 10000,
			// 				dangerouslyUseHTMLString: true,
			// 				message: this.notifyMessageList[i].message,
			// 			});
			// 		}, i * 500);
			// 	}
			// 	window.sessionStorage.setItem("homeNotif", '1');
			// },
			toPageName(event, name, query) {
				event.stopPropagation();
				if (!query) {
					query = {};
				}
				if (this.filterData.agentUser) {
					query.agentUser = this.filterData.agentUser;
				}
				this.$router.push({
					name: name,
					query: query
				})
			},
			// 一件代发 出库情况
			getOutDataByDropShipping() {
				this.loading_1 = true;
				this.$http
					.put(this.$urlConfig.WhSDSDayOutStatistics, {
						proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					})
					.then(({
						data
					}) => {
						this.loading_1 = false;
						if (200 == data.code && data.data) {
							this.OutDataByDS = data.data;
						} else {
							// this.$alert(data.msg ? data.msg : '一件代发-出库情况数据,请求失败', this.$t('i18nn_cc62f4bf31d661e3'), {
							// 	type: 'warning'
							// });
							// this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_1 = false;
						// this.$alert('一件代发-出库情况数据,请求失败！', this.$t('i18nn_cc62f4bf31d661e3'), {
						// 	type: 'warning'
						// });
						// this.$message.warning(this.$t('tips.queryRequestFailed'));
					});
			},
			// 自提单 出库情况
			getOutDataByTransferringOrder() {
				this.loading_2 = true;
				this.$http
					.put(this.$urlConfig.WhSTFDayOutStatistics, {
						proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					})
					.then(({
						data
					}) => {
						this.loading_2 = false;
						if (200 == data.code && data.data) {
							this.OutDataByTO = data.data;
						} else {
							// this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_2 = false;
						// this.$message.warning(this.$t('tips.queryRequestFailed'));
					});
			},
			// 订单统计
			getOrderStatisticsData() {
				this.loading_3 = true;
				this.$http
					.put(this.$urlConfig.WhMonthOrderTotal, {
						proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					})
					.then(({
						data
					}) => {
						this.loading_3 = false;
						if (200 == data.code && data.data) {
							this.OrderStatisticsData = data.data;
						} else {
							// this.$message.warning(this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_3 = false;
						// this.$message.warning(this.$t('tips.queryRequestFailed'));
					});
			},
			// 入库申请
			getInWhStatisticsData() {
				this.loading_4 = true;
				this.$http
					.put(this.$urlConfig.WhDayInWhStatistics, {
						proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					})
					.then(({
						data
					}) => {
						this.loading_4 = false;
						if (200 == data.code && data.data) {
							this.InWhStatisticsData = data.data;
						} else {
							// this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_4 = false;
						// this.$message.warning(this.$t('tips.queryRequestFailed'));
					});
			},
			//增值服务工单
			getValueAddStatisticsData() {
				this.loading_5 = true;
				this.$http
					.put(this.$urlConfig.WhAddValueStatistics, {
						proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					})
					.then(({
						data
					}) => {
						this.loading_5 = false;
						if (200 == data.code && data.data) {
							this.valueAddData = data.data;
						} else {
							// this.$message.warning(data.msg ? data.msg : this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_5 = false;
						// this.$message.warning(this.$t('tips.queryRequestFailed'));
					});
			},
			//转运
			getSTRStatisticsData() {
				this.loading_6 = true;
				this.$http
					.put(this.$urlConfig.WhSTRQueryStatistics, {
						// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					})
					.then(({
						data
					}) => {
						this.loading_6 = false;
						if (200 == data.code && data.data) {
							// if (data.data.STR) {
							// 	this.STRData = data.data.STR;
							// }
							if (data.data.TSCC) { //入库
								this.TSCCData = data.data.TSCC;
							}
						} else {
							// this.$message.warning(data.msg ? data.msg : '转运,请求失败');
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_6 = false;
						// this.$message.warning('转运,请求失败！');
					});
			},
			// 仓库容量统计
			getCargoVolumeStatisticsData() {
				// this.CargoVolumeData_dateStr = this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime())
				this.loading_7 = true;
				this.$http
					.put(this.$urlConfig.WhSkuDayStockCurrentVolume, {
						proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
						"startTime": this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime())
					})
					.then(({
						data
					}) => {
						this.loading_7 = false;
						if (200 == data.code && data.rows) {
							let allData = data.rows;
							let totalVol = 0;
							let totalWeight = 0;
							allData.forEach(item => {
								totalVol = totalVol + parseFloat(item.totalVol);
								totalWeight = totalWeight + parseFloat(item.totalWeight);
							})
							// this.CargoVolumeData.totalVol = totalVol;
							// this.CargoVolumeData.totalWeight = totalWeight;
							this.CargoVolumeData = {
								totalVol: totalVol.toFixed(2),
								totalWeight: totalWeight.toFixed(2)
							}
						} else {
							// this.$message.warning(this.$t('tips.queryErrorFailed'));
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_7 = false;
						// this.$message.warning(this.$t('tips.queryRequestFailed'));
					});
			},

			//未上线运单
			getExpressTrunckTotal() {
				this.loading_8 = true;
				this.$http
					.put(this.$urlConfig.WhExpressTrunckNotOnlineTotal, {
						// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					})
					.then(({
						data
					}) => {
						this.loading_8 = false;
						if (200 == data.code && data.data) {
							this.ExpressTrunckData = data.data;
						} else {
							// this.$message.warning(data.msg ? data.msg : '转运,请求失败');
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_8 = false;
						// this.$message.warning('转运,请求失败！');
					});
			},

		}
	};
</script>
<style scoped lang="less">
	@import url(@/assets/css/home.less);

	.el-main {
		background-color: #f5f5f5;
	}
</style>